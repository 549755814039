<template>
  <!-- BEGIN: Content-->
  <div class="body-login">
    <div class="content-overlay" />
    <div class="content-wrapper">
      <div class="content-header row" />
      <div class="content-body">
        <div class="auth-wrapper auth-cover">
          <div class="auth-inner row m-0">
            <!-- Brand logo-->
            <a
              class="brand-logo"
              href="https://www.icrea.cat"
            />

            <!-- /Brand logo-->
            <!-- Left Text-->
            <div
              class="d-none d-lg-flex col-lg-8 align-items-center p-5"
              :style="{'background': 'url(' + require('@/assets/images/illustration/bg-login.svg') + ') center center no-repeat', 'background-size': 'cover'}"
            >
              <div class="w-100 d-lg-flex align-items-center justify-content-center flex-column px-5">
                <div class="mb-4">
                  <img
                    class="img-fluid"
                    :src="require('@/assets/images/logo/logo-icrea-white-login.svg')"
                    alt="ICREA"
                  >
                </div>
                <!-- <div class="quote">
                  <h1><em>"You never fail until you stop trying"</em></h1>
                  <hr>
                  <p>Albert Einstein</p>
                </div> -->
              </div>
            </div>
            <!-- /Left Text-->
            <!-- Login-->
            <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
              <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title fw-bold mb-1">
                  The new password has been generated successfully
                </h2>
                <p class="card-text mb-2">
                  If you do not receive the email with the new password, check your spam folder or make sure to enter the same email as in the registration.
                </p>

                <p class="text-center mt-2">
                  <a
                    class="btn btn-primary w-100"
                    @click="$route.name == 'reset-password-sended-intranet' ? $router.push({ name: 'admin.login' }) : $router.push({ name: 'front.login' })"
                  ><span>Go to login</span></a>
                </p>

              </div>
            </div>
            <!-- /Login-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

export default {

}
</script>

<style>

</style>
